<template>
  <div id="dialogSettingRawMaterial">
    <v-dialog v-model="dialog" max-width="500" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">{{ dialogTitle }}</span
          ><v-divider class="mx-4" inset vertical></v-divider>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" v-if="isEdit">
                <v-text-field
                  v-model="item.material_type_id"
                  label="รหัส"
                  dense
                  required
                  readonly
                  disabled
                  ref="material_id"
                  @keydown.enter="NextFocus('material_type_name')"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="item.material_type_name"
                  label="ชื่อ"
                  dense
                  required
                  ref="material_type_name"
                  @keydown.enter="NextFocus('description')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="item.description"
                  type="string"
                  value="0"
                  label="หมายเหตุ"
                  dense
                  required
                  ref="description"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" v-if="isEdit">
                <v-autocomplete
                  v-model="item.status"
                  :items="enumStatus"
                  item-value="id"
                  item-text="name"
                  :return-object="false"
                  dense
                  label="สถานะ"
                  ref="status"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primary--text" @click="cancel"> ยกเลิก </v-btn>
          <v-btn class="primary" @click="submit"> บันทึก </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import baseEnum from "@js/enum";
import { mapActions, mapState } from "vuex";

export default {
  name: "DialogSettingRawMaterial",
  props: ["modal", "item", "itemIndex"],
  data() {
    return {
      currentImage: null,
      previewImage: null,
      filename: null,
      imagePath: "",
      previewOriginal: "",
      enumStatus: baseEnum.status.data,
      dialog: false,
      item_edit: {},
      indexEdit: -1,
    };
  },
  mounted() {
  },
  computed: {
    ...mapState("auth", ["user"]),
    dialogTitle() {
      return this.indexEdit == -1 ? "เพิ่ม" : "แก้ไข";
    },
    isEdit() {
      return this.indexEdit == -1 ? false : true;
    },
  },
  methods: {
    ...mapActions("settings", [
      "materialTypeCreate",
      "materialTypeEdit",
    ]),
    closeModal() {
      this.$emit("close", false);
      this.previewOriginal = ""
    },
    cancel() {
      this.closeModal();
    },
    
    submit() {
      this.currentImage = null;
      this.previewImage = null;
      this.filename = null;
      this.previewOriginal = null;

      const payload_body = {
        material_type_id: this.item.material_type_id,
        material_type_name: this.item.material_type_name || "",
        status: this.item.status === 0 ? this.item_edit.status : 1,
        description: this.item.description || "",
        created_by: this.user.UserId,
        modified_by: this.user.UserId,
      }
      console.log("payload_body", payload_body)


      if (this.indexEdit == -1) {
        //เพิ่ม
        this.materialTypeCreate(payload_body).then(({ status, data }) => {
          if (status == 200 && data.status == "success") {
            this.closeModal();
          } else {
            this.Error(data.result);
          }
        });
      } else {
        //แก้ไข
        console.log(this.item);
        this.materialTypeEdit(payload_body).then(({ status, data }) => {
          if (status == 200 && data.status == "success") {
            this.closeModal();
          } else {
            this.Error(data.result);
          }
        });
      }
      this.imagePath = null
    },
  },
  watch: {
    modal() {
      this.dialog = this.modal;
    },
    item() {
      this.item_edit = this.item;
      this.item_edit.minimum = this.item.minimum || 0;
      this.item_edit.qty = this.item.qty || 0;
      this.previewOriginal = this.item_edit.photo
      this.imagePath = this.item_edit.photo
    },
    itemIndex() {
      this.indexEdit = this.itemIndex;
    },
  },
};
</script>

<style></style>
